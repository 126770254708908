import React from "react";
import tw, { css } from "twin.macro";

import BookCover from "../../static/images/book-cover.jpeg";
import DarkContainer from "../components/DarkContainer";
import Hero from "../components/Hero";
import { Layout } from "../components/Layout/Layout";
import LogosBlock from "./index/LogosBlock";
import FreeReport from "./opt_in/FreeReport";
import SuccessfulBlueprint from "./opt_in/SuccessfulBlueprint";
import UnlockSecrets from "./opt_in/UnlockSecrets";

const sectionSpacing = css`
  section {
    ${tw`py-8 sm:(py-12)`}
  }
`;
const styles = css`
  section {
    ${tw`text-center sm:text-left`}
  }
  article {
    ${tw`max-w-5xl mx-auto flex flex-wrap items-center`}
  }
  h1,
  h2 {
    ${tw`text-4xl lg:text-5xl font-black my-4`}
  }
  p {
    ${tw`text-base sm:text-xl`}
  }
  img {
    ${tw`mx-auto mb-8 w-2/3 sm:w-auto`}
  }
`;
const partners = css`
  section {
    ${tw`pb-8`}
  }
  article {
    ${tw`block text-center`}
  }
`;

const OptIn: React.FC = () => {
  return (
    <Layout
      css={[styles, sectionSpacing]}
      altHeader={true}
      altFooter={true}
      title={"Free Report"}
      ogTitle={"Get your free report"}
      author={"Triangular-pod"}
      ogImage={BookCover}
      ogDescription={
        "Download our exclusive free report, and save yourself years of costly trial and error by using the blueprint to be extremely successful in material processing projects."
      }
      ogURL={"www.triangular-pod.com/free-report/"}
    >
      <Hero>
        <FreeReport />
      </Hero>
      <LogosBlock css={partners} />
      <DarkContainer>
        <UnlockSecrets />
      </DarkContainer>
      <SuccessfulBlueprint />
    </Layout>
  );
};

export default OptIn;
