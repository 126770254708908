import React from "react";
import { useTranslation } from "react-i18next";
import tw, { css } from "twin.macro";

import { Container } from "../../components/Container";
import ListWithChecks from "../../components/ListWithChecks";
import { SendReportButton } from "../../components/SendReport/SendReportButton";
import { getTranslationList } from "../../utils/common";

const styles = css`
  article {
    ${tw`text-center items-center max-w-4xl`}
  }
  p,
  div,
  ul {
    ${tw`mx-auto`}
  }
  ul {
    ${tw`sm:mx-32 sm:(text-xl)`}
  }
  svg {
    ${tw`sm:mt-0`}
  }
`;

const UnlockSecrets: React.FC = ({ ...rest }) => {
  const { t } = useTranslation();

  const secretsList = getTranslationList(t, "optIn.secretsBlock.list");

  return (
    <Container>
      <section css={styles}>
        <article {...rest}>
          <p>{t("optIn.secretsBlock.limitedTime")}</p>
          <h2>{t("optIn.secretsBlock.header")}</h2>
          <p>{t("optIn.secretsBlock.description")}</p>
          <ListWithChecks list={secretsList} />
          <SendReportButton commentFirst={true} />
        </article>
      </section>
    </Container>
  );
};
export default UnlockSecrets;
